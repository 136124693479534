<template>
  <div class="infinite-grid-component">
    <div class="block w-full infinite-grid-block" :key="index" v-for="(block, index) in blocks">
      <slot name="block" :blocks="blocks">
        <div class="_infinite-grid grid gap-2">
          <div class="infinite-grid-item" :key="itemIndex" v-for="(item, itemIndex) in block.data">
            <slot name="item" :item="item">
              {{item.id}}
            </slot>
          </div>
        </div>
      </slot>
    </div>
    <div class="flex w-full justify-center my-4">
      <vs-button v-if="!noMoreResults && !firstBlockEmpty" @click="fetchData(false)" class="flex-grow-0 flex-shrink-0" icon="add">
        {{ $t('ver-mais') }}
      </vs-button>
      <span v-if="firstBlockEmpty"> {{ $t('nenhum-registro-encontrado') }} </span>
    </div>
  </div>
</template>

<script>

export default {

  props: {
    orderColumn: {
      default: null,
      type: String
    },
    direction: {
      default: null,
      type: String
    },
    itemPerBlock: {
      default: 20,
      type: Number
    },
    service: {
      default: null,
      type: Object
    },
    gridPath: {
      default: '',
      type: String
    },
    showLoading: {
      default: true,
      type: Boolean
    },
    method: {
      default: null,
      type: Function
    },
    extraParams: {
      default: null,
      type: Object,
      required: false
    }
  },

  data: () => ({
    blocks: [],
    filter: {
      search: '',
      custom: {

      }
    },
  }),

  mounted() {
    this.fetchData(true)
  },

  computed: {
    noMoreResults() {
      return this.lastBlock !== null ? this.lastBlock.last : true
    },
    lastBlock() {
      return (this.blocks.length > 0) ? this.blocks[this.blocks.length - 1] : null
    },
    firstBlockEmpty() {
      const blockData = this.blocks && this.blocks.length > 0 ? this.blocks[0].data : {}
      return 'length' in blockData ? blockData.length === 0 : true
    }
  },

  methods: {
    dlog(...things) {
      const discriminator = (this.service && this.service.constructor.name) || '?'
      this.devLog(`infinite-grid<${discriminator}>`, ...things)
    },
    wipeBlocks() {
      this.blocks = []
    },
    appendBlock(model) {
      const newModel = this._.cloneDeep(model)
      const block = {
        data: newModel.data,
        offset: newModel.from,
        to: newModel.to,
        last: newModel.last_page
      }
      this.blocks.push(block)
      return {
        block,
        index: this.blocks.length - 1
      }
    },
    fetchData(reset = false) {
      if (this.showLoading) {
        this.$vs.loading()
      }
      let lastBlock = 0
      if (reset === true) {
        this.wipeBlocks()
      } else {
        lastBlock = this.blocks.length
        // lastPage = this.blocks.length > 0 ? (this.blocks[this.blocks.length - 1].to + 1) : 1
      }

      if (this.service !== null) {
        return new Promise((resolve, reject) => {
          const params = {
            page: lastBlock + 1,
            per_page: this.itemPerBlock,
            filters: {
              items: [this.filter.search]
            },
            custom_filters: this.filter.custom || []
          }

          if (this.orderColumn) {
            params.order_column = this.orderColumn
          }

          if (this.direction) {
            params.direction = this.direction
          }

          if(this.extraParams){
            Object.entries(this.extraParams).map(([k,v])=> params[k]=v )
          }

          const method = this.method || 'grid'

          this.service[method](this.gridPath, params).then(
            (response) => {
              this.$vs.loading.close()
              const newBlock = this.appendBlock(response.model)
              this.$emit('infinite-grid-updated', newBlock)
              this.$emit('count', this.blocks.length)
              resolve(newBlock)
            },
            (error) => {
              this.$vs.loading.close()
              reject(error)
            }
          )
        })
      } else {
        this.$vs.loading.close()
      }
    },
    flashFilters(item, custom) {
      let search = ''
      if (!this.isEmpty(item)) {
        const searchFor = Array.isArray(item) ? item[0] : item
        search = searchFor ? searchFor : ''
      }

      this.filter.search = search
      this.filter.custom = custom
      return this.fetchData(true)
    }
  }
}
</script>

<style lang="scss" scoped>
  .infinite-grid-component > .infinite-grid-block {
    margin-bottom: 0.5rem;
  }
</style>
