var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "infinite-grid-component" },
    [
      _vm._l(_vm.blocks, function (block, index) {
        return _c(
          "div",
          { key: index, staticClass: "block w-full infinite-grid-block" },
          [
            _vm._t(
              "block",
              function () {
                return [
                  _c(
                    "div",
                    { staticClass: "_infinite-grid grid gap-2" },
                    _vm._l(block.data, function (item, itemIndex) {
                      return _c(
                        "div",
                        { key: itemIndex, staticClass: "infinite-grid-item" },
                        [
                          _vm._t(
                            "item",
                            function () {
                              return [_vm._v(" " + _vm._s(item.id) + " ")]
                            },
                            { item: item }
                          ),
                        ],
                        2
                      )
                    }),
                    0
                  ),
                ]
              },
              { blocks: _vm.blocks }
            ),
          ],
          2
        )
      }),
      _c(
        "div",
        { staticClass: "flex w-full justify-center my-4" },
        [
          !_vm.noMoreResults && !_vm.firstBlockEmpty
            ? _c(
                "vs-button",
                {
                  staticClass: "flex-grow-0 flex-shrink-0",
                  attrs: { icon: "add" },
                  on: {
                    click: function ($event) {
                      return _vm.fetchData(false)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("ver-mais")) + " ")]
              )
            : _vm._e(),
          _vm.firstBlockEmpty
            ? _c("span", [
                _vm._v(
                  " " + _vm._s(_vm.$t("nenhum-registro-encontrado")) + " "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }